import { render, staticRenderFns } from "./GSheet.vue?vue&type=template&id=323f1b34&scoped=true&"
import script from "./GSheet.vue?vue&type=script&lang=ts&"
export * from "./GSheet.vue?vue&type=script&lang=ts&"
import style0 from "./GSheet.vue?vue&type=style&index=0&id=323f1b34&prod&scoped=true&lang=sass&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "323f1b34",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VSheet } from 'vuetify/lib/components/VSheet';
installComponents(component, {VSheet})
